<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Харилцагчийн автомат цуцлалт</h3>
    </div>
    <el-row :gutter="0" v-loading="loading">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <el-row :gutter="0">
            <el-col :span="24">
              <el-row>
                <el-col :span="21">
                  <el-form :inline="true" class="demo-form-inline" size="mini">
                    <el-row :gutter="20">
                      <el-col :span="24">
                        <el-form-item label="Харилцагчийн нэр">
                          <el-input
                            clearable
                            placeholder="Харилцагчийн нэр"
                            size="mini"
                            v-model="outletName"
                          />
                        </el-form-item>
                        <el-form-item>
                          <el-button
                            type="primary"
                            icon="el-icon-search"
                            @click="noSalesPartners()"
                            >Хайх</el-button
                          >
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-col>
              </el-row>
              <el-table :data="partnerList" style="width: 100%">
                <el-table-column type="index" label="№" width="80">
                </el-table-column>
                <el-table-column prop="full_name_mon" label="Харилцагчийн нэр">
                </el-table-column>
                <el-table-column label="Үйлчилгээ">
                  <template slot-scope="scope">
                    <el-row type="flex" style="gap: 5px">
                      <el-tag
                        v-for="(service, index) in scope.row.service_list"
                        :key="index"
                        >{{ service }}</el-tag
                      >
                    </el-row>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="email" label="И-мэйл хаяг">
                </el-table-column>
                <el-table-column prop="address" label="Харилцагчийн хаяг">
                </el-table-column> -->
                <el-table-column
                  prop="no_sales_days"
                  label="Борлуулалт гараагүй өдөр"
                >
                </el-table-column>
              </el-table>
              <el-pagination
                class="text-right mt10"
                background
                layout="sizes, prev, pager, next, total"
                :total="count"
                :page-sizes="[10, 20, 30, 40]"
                @current-change="setPage"
                @size-change="setPageSize"
              >
              </el-pagination>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import services from "../helpers/services";
export default {
  name: "noSalespartner",
  mounted() {
    this.noSalesPartners();
  },
  data() {
    return {
      partnerList: [],
      count: 0,
      size: 10,
      from: 0,
      outletName: "",
      email: "",
      loading: false
    };
  },
  methods: {
    setPage(page) {
      this.from = (page - 1) * this.size;
      this.noSalesPartners();
    },
    setPageSize(size) {
      this.size = size;
      this.noSalesPartners();
    },
    noSalesPartners() {
      this.loading = true;
      services
        .getNoSalesPartner(this.size, this.from, this.outletName)
        .then(response => {
          if (response.data.status === "success") {
            this.partnerList = response.data.data;
            this.count = response.data.total;
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
    }
  }
};
</script>
